import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
// Selectors
import { selectors } from '../../engine/config/selectors';
import { userRoles } from '../../engine/config/userRoles';
import { useUserSomeRole } from './useUserSomeRole';

export function useChat() {
  const userInfo = useSelector(selectors.user.userInfo);
  const isAdmin = useUserSomeRole([
    userRoles.admin,
    userRoles.contentManager,
    userRoles.callCenter,
    userRoles.salesManager,
    userRoles.creditDepartmentManager,
    userRoles.partnerDisabled,
    userRoles.manager,
  ]);
  const didInit = useRef(false);

  useEffect(() => {
    const callbackScriptId = 'allo-callback-script';
    const loaderScriptId = 'allo-loader-script';
    const reloadFlagKey = 'hasReloadedAlloWidget';

    const removeScripts = () => {
      const callbackScript = document.getElementById(callbackScriptId);
      const loaderScript = document.getElementById(loaderScriptId);
      if (callbackScript) {
        document.body.removeChild(callbackScript);
      }
      if (loaderScript) {
        document.body.removeChild(loaderScript);
      }
    };

    if (!userInfo.email) {
      removeScripts();
      const alreadyReloaded = sessionStorage.getItem(reloadFlagKey);
      if (!alreadyReloaded) {
        sessionStorage.setItem(reloadFlagKey, 'true');
        window.location.reload();
      }
      return;
    }

    if (isAdmin) {
      removeScripts();
      return;
    }

    if (didInit.current) {
      return;
    }
    didInit.current = true;

    sessionStorage.removeItem(reloadFlagKey);
    removeScripts();

    const callbackScript = document.createElement('script');
    callbackScript.id = callbackScriptId;
    callbackScript.text = `
      window.alloCallback = function() {
        AlloWidget.init({
          companyId: "allo-marketplace",
          m_id: "${userInfo.partnerMid || ''}",
          email: "${userInfo.email || ''}",
          partnername: "${userInfo.name || ''}"
        });
      }
    `;
    document.body.appendChild(callbackScript);

    const loaderScript = document.createElement('script');
    loaderScript.id = loaderScriptId;
    loaderScript.text = `
      (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        js = d.createElement(s);
        js.id = id;
        js.src = "https://nord.allo.ua/init.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'allo-widget');
    `;
    document.body.appendChild(loaderScript);

    return removeScripts;
  }, [userInfo.email, userInfo.partnerMid, userInfo.name, isAdmin]);
}
